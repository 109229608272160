@use "./../VCX/base";

.dg_inner {
  position: relative;
  display: flex;
  flex: 1 1 auto;

  .dg_table {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: auto;
    background-color: var(--ColorMap-DataBrowseColorBck);
    border-left: 1px solid var(--ColorMap-ContentFrame1);
    border-right: 1px solid var(--ColorMap-ContentFrame1);

    th,
    td {
      border: 1px solid var(--ColorMap-ContentFrame1);
    }

    th:first-child,
    td:first-child {
      border-left: none;
    }

    tr:first-child td {
      border-top: none;
    }

    &.dg_table_edit {
      background-color: var(--ColorMap-DataChangeColorBck);
    }

    .dg_header_wrapper {
      display: block;
      overflow: hidden;
      flex: 0 0 auto;
    }

    .dg_rows_wrap {
      position: relative;
      flex: 1 1 auto;

      .dg_rows {
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: auto;
        overflow-y: hidden;
        overscroll-behavior: contain;

        &.dg_rows_scrollable {
          overflow-y: auto;
        }

        .dg_body {
          border-collapse: collapse;
          table-layout: fixed;
          background-color: var(--ColorMap-DataBrowseColorBck);
          @include base.grid_font;

          &.dg_body_edit {
            background-color: var(--ColorMap-DataChangeColorBck);
          }

          .dg_tbody {
            color: var(--ColorMap-DataBrowseColorFrg);

            .dg_td {
              .dg_triangle {
                position: absolute;
                width: 0;
                height: 0;
                border-right: calc(var(--Zoom) * 8px) solid transparent;
                border-top: calc(var(--Zoom) * 8px) solid var(--ColorMap-ContentChangeDecorateColorFrg);

                &.dg_triangle_error {
                  border-top: calc(var(--Zoom) * 8px) solid var(--ColorMap-ErrorColorBck);
                }
              }
            }
          }
        }
      }
    }

    .dg_aggregation_wrapper {
      display: block;
      overflow: hidden;
      flex: 0 0 auto;

      .dg_aggregation {
        border-collapse: collapse;
        table-layout: fixed;
        background-color: var(--ColorMap-DataBrowseColorBck);
        height: calc(var(--GridControl-RowHeight) * 1px);
        @include base.grid_font;

        .dg_aggregation_column {
          height: 100%;

          .dg_aggregation_cell {
            padding: 0px 6px;
            border: 1px solid var(--ColorMap-BaseColorBck1);
            background-color: var(--ColorMap-DataBrowseColorBck);
            color: var(--ColorMap-DataBrowseColorFrg);
            height: 100%;
          }
        }
      }
    }

    .dg_invalid_data {
      opacity: 0.6;
    }

    .dg_drag_handle {
      border: 1px solid #000;
      position: absolute;
      height: 100%;
      z-index: 1;
    }
  }
}

.dg_footer {
  display: flex;
  overflow-x: auto;
  justify-content: space-between;
  background-color: var(--ColorMap-DataChangeROColorBck);
  height: auto;
  color: var(--ColorMap-ContentDecorateColorFrg);
  border: 1px solid var(--ColorMap-ContentFrame1);
}

.dg_header {
  border-collapse: collapse;
  height: calc(var(--GridControl-HeaderRowHeight) * 1px);
  table-layout: fixed;

  .dg_header_row_base {
    height: calc(var(--GridControl-RowHeight) * 1px);
  }

  .dg_header_row {
    background-color: var(--ColorMap-ContentColorBck1);
    color: var(--ColorMap-ContentDecorateColorFrg);
  }

  .dg_filter {
  }
}

.dg_header_column {
  @include base.grid_header_font;

  .dg_header_column_content {
    height: 100%;
    align-items: center;
    background-color: var(--ColorMap-DataChangeROColorBck);
    padding: 0px 6px;
  }

  .dg_handle {
    position: absolute;
    width: 10px;
    height: 100%;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 1;
    & > div {
      height: 100%;
      width: 1px;
      margin: auto;
    }

    &.dg_handle_last_column {
      right: 0;
      & > div {
        margin-left: 10px;
      }
    }
  }

  @media (max-width: 980px) {
    .dg_handle {
      width: 25px;
      right: -13px;
    }
  }
}

.dg_fixed_col {
  position: sticky;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    right: -1px;
    top: 0px;
    border-left: 1px solid var(--ColorMap-ContentFrame1);
  }
}

.dg_cell {
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;
  height: 100%;
  border: 1px solid transparent;
  padding: 0 4px;
  overflow: hidden;

  &.dg_cell_selected {
    border: 1px dotted var(--ColorMap-ContentFrame3);
  }

  &.dg_cell_cancelled {
    color: #00000070;
  }

  &.dg_cell_link:hover {
    color: var(--ColorMap-ContentChangeDecorateColorFrg);
    cursor: pointer;
  }
}
