@use "./../VCX/base";

.menu {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  background-color: #fff;
  padding: 1px;

  .menu_sub {
    position: fixed;
    border: 1px solid #ababab;
    display: flex;
    flex-direction: column;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.3);
    overflow: auto;
  }

  .menu_button {
    @include base.label_font;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    flex: 1 1 auto;
    padding: 0px 5px;

    .menu_hotkey {
      margin-right: calc(var(--Zoom) * 10px);
    }
    &.active {
      background-color: #d9d9d9;
    }
  }

  .menu_responsive_actions {
    display: none;
  }
}

@media (max-width: 980px) {
  .menu {
    .menu_responsive_actions {
      display: flex;
      flex: 0 0 auto;
      overflow: auto;
      box-shadow: inset 5px 0px 10px -10px rgb(0 0 0 / 50%), inset -5px 0px 10px -10px rgb(0 0 0 / 50%);
      border-bottom: 1px solid var(--ColorMap-ContentFrame1);

      .menu_responsive_action {
        margin: calc(var(--Zoom) * 5px);
        display: flex;
        flex: 0 0 auto;
      }
    }
  }
}
