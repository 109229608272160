:root {
  --LabelControl-Font-Name: Segoe UI;
  --LabelControl-Font-Size: 9;
  --LabelControl-Font-Weight: normal;
  --LabelControl-Font-Style: normal;
  --LabelControl-Font-Decoration: normal;

  --ExpanderControl-HeaderFont-Name: Segoe UI;
  --ExpanderControl-HeaderFont-Size: 10;
  --ExpanderControl-HeaderFont-Weight: bold;
  --ExpanderControl-HeaderFont-Style: normal;
  --ExpanderControl-HeaderFont-Decoration: normal;

  --BookTabControl-AccessorFont-Name: Segoe UI;
  --BookTabControl-AccessorFont-Size: 8;
  --BookTabControl-AccessorFont-Weight: normal;
  --BookTabControl-AccessorFont-Style: normal;
  --BookTabControl-AccessorFont-Decoration: normal;

  --GridControl-Font-Name: Segoe UI;
  --GridControl-Font-Size: 10;
  --GridControl-Font-Weight: normal;
  --GridControl-Font-Style: normal;
  --GridControl-Font-Decoration: normal;

  --GridControl-HeaderFont-Name: Segoe UI;
  --GridControl-HeaderFont-Size: 9;
  --GridControl-HeaderFont-Weight: normal;
  --GridControl-HeaderFont-Style: normal;
  --GridControl-HeaderFont-Decoration: normal;

  --InputControl-LabelFont-Name: Segoe UI;
  --InputControl-LabelFont-Size: 9;
  --InputControl-LabelFont-Weight: normal;
  --InputControl-LabelFont-Style: normal;
  --InputControl-LabelFont-Decoration: normal;

  --InputControl-EditFont-Name: Segoe UI;
  --InputControl-EditFont-Size: 10;
  --InputControl-EditFont-Weight: normal;
  --InputControl-EditFont-Style: normal;
  --InputControl-EditFont-Decoration: normal;

  --ListViewControl-Font-Name: Segoe UI;
  --ListViewControl-Font-Size: 8;
  --ListViewControl-Font-Weight: normal;
  --ListViewControl-Font-Style: normal;
  --ListViewControl-Font-Decoration: normal;

  --TreeViewControl-Font-Name: Segoe UI;
  --TreeViewControl-Font-Size: 10;
  --TreeViewControl-Font-Weight: normal;
  --TreeViewControl-Font-Style: normal;
  --TreeViewControl-Font-Decoration: normal;

  --DashboardControl-TileFont-Name: Segoe UI;
  --DashboardControl-TileFont-Size: 14;
  --DashboardControl-TileFont-Weight: normal;
  --DashboardControl-TileFont-Style: normal;
  --DashboardControl-TileFont-Decoration: normal;

  --DashboardControl-TileSmallFont-Name: Segoe UI;
  --DashboardControl-TileSmallFont-Size: 8;
  --DashboardControl-TileSmallFont-Weight: normal;
  --DashboardControl-TileSmallFont-Style: normal;
  --DashboardControl-TileSmallFont-Decoration: normal;

  --DashboardControl-FilterInnerFont-Name: Segoe UI;
  --DashboardControl-FilterInnerFont-Size: 9;
  --DashboardControl-FilterInnerFont-Weight: normal;
  --DashboardControl-FilterInnerFont-Style: normal;
  --DashboardControl-FilterInnerFont-Decoration: normal;

  --DashboardControl-SimpleTableFont-Name: Segoe UI;
  --DashboardControl-SimpleTableFont-Size: 8;
  --DashboardControl-SimpleTableFont-Weight: normal;
  --DashboardControl-SimpleTableFont-Style: normal;
  --DashboardControl-SimpleTableFont-Decoration: normal;

  --TitleControl-Font-Name: Segoe UI;
  --TitleControl-Font-Size: 15;
  --TitleControl-Font-Weight: normal;
  --TitleControl-Font-Style: normal;
  --TitleControl-Font-Decoration: normal;

  --GridControl-LineWidth: 1;
  --Zoom: 1;
  --MarginX: 3;
  --MarginY: 3;
  --InputControl-FrameWidth: 1;
  --InputControl-Height: 1;
  --InputControl-InteriorBorder: 0;
  --LabelControl-Height: 16;
  --GridControl-HeaderRowHeight: 20;
  --GridControl-RowHeight: 20;
  --ExpanderControl-HFHeight: 25;
  --ExpanderControl-LiteSeparationWidth: 2;
  --SplitterControl-Size: 7;

  --ColorMap-ContentFrameMin: #e0e0e0;
  --ColorMap-ContentFrameMinForChange: #bbebff;
  --ColorMap-ContentFrame1: #cccccc;
  --ColorMap-ContentFrame1ForChange: #91deff;
  --ColorMap-ContentFrame2: #b6b6b6;
  --ColorMap-ContentFrame2ForChange: #68d1ff;
  --ColorMap-ContentFrame3: #7e7e7e;
  --ColorMap-ContentFrame3ForChange: #00aef9;
  --ColorMap-DataBrowseColorBck: #fcfcfc;
  --ColorMap-DataBrowseColorFrg: #000000;
  --ColorMap-DataChangeColorBck: #ffffe1;
  --ColorMap-DataChangeColorFrg: #000000;
  --ColorMap-AccentBaseColorFrg: #ffffff;
  --ColorMap-AccentBaseColorBck: #00aeef;
  --ColorMap-BaseColorFrg1: #ffffff;
  --ColorMap-BaseColorBck1: #0054a6;
  --ColorMap-ContentChangeColorBck: #d9f4ff;
  --ColorMap-ContentDecorateColorFrg: #0054a6;
  --ColorMap-ContentNormalColorFrg: #000000;
  --ColorMap-ContentColorBck1: #f0f0f0;
  --ColorMap-ContentChangeDecorateColorFrg: #009fe3;
  --ColorMap-WarningColorBck: #ffa500;
  --ColorMap-WarningColorFrg: #ffffff;
  --ColorMap-ErrorColorBck: #ff4444;
  --ColorMap-ErrorColorFrg: #ffffff;
  --ColorMap-HighlightColorBck: #99cc00;
  --ColorMap-HighlightColorFrg: #ffffff;
  --ColorMap-GridRulerColorBck: #cceffc;
  --ColorMap-DataChangeROColorFrg: #000000;
  --ColorMap-DataChangeROColorBck: #f0f0f0;

  --ColorMap-Gray: 0;
  --ColorMap-LumaShift: #000;
  --ColorMap-Alpha: #000;
  --ColorMap-AlphaColor: #000;
  --ColorMap-InactiveAlphaValue: 40;
  --ColorMap-InactiveAlphaColor: #000;

  --ColorMap-HintBackgroundColor1: #fcfae0;
  --ColorMap-HintBackgroundColor2: #fcfae0;
  --ColorMap-HintFontColor: #000;

  /* Flow Chart*/
  --FlowChart-Step-BorderColor: #000;
  --FlowChart-Step-BackgroundColor: #fff;
}
