@use "./../VCX/base";

.gb {
  display: flex;
  flex-direction: column;
  gap: calc(var(--MarginY) * 2px);
}

.gb_title {
  flex: 0 0 auto;
  align-items: flex-end;
  min-height: calc(var(--InputControl-Height) * 1px);
  color: var(--ColorMap-ContentDecorateColorFrg);
  border-bottom: calc(var(--Zoom) * 2px) solid var(--ColorMap-AccentBaseColorBck);
  padding-bottom: calc(var(--Zoom) * 4px);

  & > p {
    padding-left: calc(var(--Zoom) * 6px);
  }
}

.gb_title_font {
  @include base.expander_header_font;
}
