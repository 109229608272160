@use "./../VCX/base";

.gantt {
  @include base.label_font;
  .gantt-wrapper {
    position: relative;

    &[data-loading="1"] {
      .no_data {
        display: none;
      }
      .loading_wrap {
        display: flex;
      }
    }
    .no_data {
      display: flex;
    }
    .loading_wrap {
      display: none;
      > div {
        display: flex;
      }
    }
  }
}
