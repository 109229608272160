.splitter {
  display: flex;
  position: relative;
  user-select: none;

  &.splitter_vertical {
    gap: calc(var(--MarginX) * 1px);
    flex-direction: row;
  }

  &.splitter_horizontal {
    gap: calc(var(--MarginY) * 1px);
    flex-direction: column;
  }

  .splitter_handle {
    background-color: var(--ColorMap-AccentBaseColorBck);
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;

    &.splitter_handle_vertical {
      width: calc(var(--SplitterControl-Size) * 1px);
      cursor: col-resize;
      transition: width 0.25s;
    }

    &.splitter_handle_horizontal {
      height: calc(var(--SplitterControl-Size) * 1px);
      cursor: row-resize;
      transition: height 0.25s;
    }

    &.splitter_handle_hide {
      visibility: hidden;
    }

    .splitter_handle_dots {
      width: calc(var(--Zoom) * 7px);
      height: calc(var(--Zoom) * 19px);
      background-image: radial-gradient(circle, white calc(var(--Zoom) * 1px), transparent calc(var(--Zoom) * 2px));
      background-size: 100% 33.33%;

      &.splitter_handle_dots_horizontal {
        transform: rotate(90deg);
      }
    }
  }

  .splitter_toggle_preview_button {
    display: none;
  }
}

@media (max-width: 980px) {
  .splitter {
    &.splitter_vertical {
      gap: 0;
    }

    &.main_splitter {
      > .splitter_item {
        @media (max-width: 980px) {
          flex-direction: column;
          height: calc(100% - 50px);
        }
      }
    }

    .splitter_handle {
      &.splitter_handle_vertical {
        display: flex;
        position: absolute;
        top: 50%;
        right: 0;
        height: calc(var(--Zoom) * 50px);
        width: calc(var(--Zoom) * 20px);
        transform: translate(0, -50%);
        z-index: 1;
      }

      &.splitter_handle_main_panel {
        display: none;
      }
    }

    .splitter_toggle_preview_button {
      display: flex;
      border-left: 1px solid var(--ColorMap-ContentFrame1);
      width: calc(var(--Zoom) * 40px);
      height: calc(var(--Zoom) * 40px);
      justify-content: center;
      align-items: center;
      background-color: var(--ColorMap-BaseColorFrg1);
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 10;
    }
  }
}

.splitter_mobile_horizontall {
  flex-direction: column;
  overflow: auto;
  height: 100%;
}
