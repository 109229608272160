.sod {
  display: flex;
  flex-direction: column;
  background-color: var(--ColorMap-ContentColorBck1);
  height: 100%;

  .sod_message {
    font-size: 16pt;
    font-weight: bold;
    text-align: center;
    padding: 6px;
  }

  .sod_files {
    display: flex;
    flex-direction: column;
    flex: 4;
    overflow: auto;
  }

  .sod_buttons {
    display: flex;
    flex: 0 0 auto;
  }
}
