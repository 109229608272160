/*flex-shrink: 1 nastavuje min-width: AUTO a nedochází tak ke zmenšování přes obsah. Nechtěné a proto přepsáno na min-width: 0; */
/*flex-shrink: 1 nastavuje min-height: AUTO a nedochází tak ke zmenšování přes obsah. Nechtěné a proto přepsáno na min-height: 0; */

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none; /*border focus */
  min-width: 0;
  min-height: 0;
  user-select: none;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* a links */
a,
a:visited {
  color: #00aeef;
}

/* Button na iOS při dvojklepnutí nevyvolá zoom */
button {
  touch-action: manipulation;
  overflow: hidden;
}

div {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.blDiv div {
  display: block;
}

.truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  white-space: pre;
}

.mainContent {
  /*hlavni obsah */
  width: 100%; /* jednotky vh a vw přetéká obsah na mobilu ...*/
  height: 100%; /* ... proto nahrazeno procenty 11.4.2019 */
  overflow: auto;
}

.testPanel {
  background-color: red;
  justify-content: center;
  color: white;
  font-size: smaller;
  flex: 0 0 auto;
}

/* .mainContent>div{
    height: 100%;
} */

.hide {
  display: none;
}

textarea {
  resize: none;
}

.mouse_only {
  display: none;
}

/* File upload */
.file-uploader--dragging {
  border-color: #000 !important;
  transition: all linear 1s;
}

#dropFileEnableText {
  display: none;
}

button.link {
  background: transparent;
  color: blue;
  border: none;
  outline: 0;
  text-decoration: underline;
}

#fullScreenMessageText {
  display: block;
  position: relative;
  width: max-content;
  max-width: 40%;
  min-width: 25%;
  margin: auto;
  padding: 0.5em 2em;
  border-radius: 10px;
  text-align: center;
  font-size: 1.5em;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #ffffff;
}

#fullScreenMessageText {
  top: 40vh;
  font-size: 1.2em;
}

#fullScreenMessageText button {
  font-size: 1em;
  padding: 0.4em 3em;
  background: #00539b;
  color: #ffffff;
  border: 0;
  border-radius: 8px;
}

.column-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.column-resizable-handle > div {
  height: 100%;
  width: 1px;
  margin: auto;
}

.last-column-resizable-handle {
  right: 0px;
}

.last-column-resizable-handle > div {
  margin-left: 10px;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

@keyframes list-detail-open {
  0% {
    transform: translateY(300px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes list-detail-close {
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    transform: translateY(300px);
    opacity: 0.25;
  }
}

@keyframes main-menu-open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes main-menu-close {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 0.25;
  }
}

.previewNotSupported {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20xmlns%3Ak2svg%3D%22http%3A%2F%2Fwww.k2.cz%2Fsvg%22%20version%3D%221.1%22%20id%3D%22disagree%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2032%2032%22%20enable-background%3D%22new%200%200%2032%2032%22%20opacity%3D%220.3%22%3E%0A%20%3Cfilter%20id%3D%22blurMe%22%3E%0A%20%20%20%20%3CfeGaussianBlur%20in%3D%22SourceGraphic%22%20stdDeviation%3D%220.5%22%2F%3E%0A%20%20%3C%2Ffilter%3E%20%20%20%20%0A%3Cpath%20id%3D%22disagree_1_%22%20d%3D%22M16%2C3C8.8%2C3%2C3%2C8.8%2C3%2C16c0%2C7.2%2C5.8%2C13%2C13%2C13c7.2%2C0%2C13-5.8%2C13-13C29%2C8.8%2C23.2%2C3%2C16%2C3z%20M5%2C16%26%2310%3B%20c0-2.7%2C1-5.1%2C2.6-7L23%2C24.4C21.1%2C26%2C18.7%2C27%2C16%2C27C9.9%2C27%2C5%2C22.1%2C5%2C16z%20M24.4%2C23L9%2C7.6C10.9%2C6%2C13.3%2C5%2C16%2C5c6.1%2C0%2C11%2C4.9%2C11%2C11%26%2310%3B%20C27%2C18.7%2C26%2C21.1%2C24.4%2C23z%22%20filter%3D%22url(%23blurMe)%22%2F%3E%0A%3Ck2svg%3Acategory%20name%3D%22circle%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: center;
}

.gantt-wrapper div {
  display: block;
}

.k2_client_dialog_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background: black;
  opacity: 0.25;
  left: 0;
  top: 0;
  z-index: 10000;
}

.k2_client_dialog {
  width: 95%;
  height: 95%;
  border: 1px solid;
  background: white;
  z-index: 10001;
  display: flex;
  position: absolute;
  left: 2.5%;
  top: 2.5%;
  padding: 4px;
  box-shadow: 5px 5px 10px 5px rgb(0 0 0 / 30%);
  flex-direction: column;
}

@media (min-width: 30rem) {
  .k2_client_dialog {
    width: 20rem;
    height: 10rem;
    border: 1px solid;
    background: white;
    z-index: 10001;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    padding: 4px;
    box-shadow: 5px 5px 10px 5px rgb(0 0 0 / 30%);
    flex-direction: column;
  }
}

.k2_client_dialog > div:first-of-type {
  flex: 1;
}

.k2_client_dialog > div:first-of-type > p {
  text-align: center;
  align-self: center;
  width: 100%;
}

.k2_client_dialog > button {
  width: 50%;
  align-self: center;
}

.no-drag {
  cursor: auto;
}
