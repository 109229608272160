@use "./../VCX/base";

.in_input_base {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.in_text_left {
  text-align: left;
}

.in_text_right {
  text-align: right;
}

.in_text_center {
  text-align: center;
}

.in_input {
  @include base.input_edit_font;
  background: transparent;
  border: none;
  width: 100%;
  min-width: calc(var(--Zoom) * 20px);
  flex: 1;
  user-select: auto;
  color: var(--ColorMap-DataChangeROColorFrg);
  &::placeholder {
    color: var(--ColorMap-DataChangeROColorFrg);
    opacity: 0.6;
    font-style: italic;
  }
}

.in_input_prefix_suffix {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding: 0px calc(var(--Zoom) * 3px);
  gap: calc(var(--Zoom) * 3px);
  input,
  textarea {
    align-self: stretch;
  }
}

.in_prefix_suffix {
  @include base.input_edit_font;
  color: var(--ColorMap-ContentDecorateColorFrg);
  overflow: hidden;
  text-overflow: ellipsis;
}

.in_toolbar {
  color: var(--ColorMap-ContentDecorateColorFrg);
}

.in_invalid_value {
  color: var(--ColorMap-ContentFrame1);
}

/* Keyboard Input*/
.in_keyboard_input_wrap {
  padding: 1px;
  position: relative;
  flex: 1 1 100%;
  display: flex;
  align-items: center;

  &.horizontal_right {
    justify-content: end;
  }
  &.horizontal_center {
    justify-content: center;
  }
  &.vertical_top {
    align-items: flex-start;
  }
  &.vertical_bottom {
    align-items: flex-end;
  }
  .in_input {
    width: inherit;
    min-width: 5px;
    &.password {
      -webkit-text-security: disc;
      text-security: disc;
    }
  }

  &:hover {
    .in_keyboard_prefix_suffix {
      &.keyboard_underline {
        &.in_edit {
          border-bottom: calc(var(--InputControl-FrameWidth) * 2px) solid var(--ColorMap-ContentFrame3ForChange);
          margin-bottom: calc(var(--InputControl-FrameWidth) * -2px + 1);
        }
      }
    }
  }
  .in_keyboard_prefix_suffix {
    z-index: 1;
    position: relative;
    display: flex;
    &.keyboard_underline {
      border-bottom: 1px solid var(--ColorMap-ContentFrame2);
      &.in_edit {
        border-bottom: calc(var(--InputControl-FrameWidth) * 1px) solid var(--ColorMap-ContentFrame3ForChange);
      }
      &.in_edit.keyboard_focus,
      &.keyboard_focus {
        &:hover {
          border-bottom: calc(var(--InputControl-FrameWidth) * 2px) solid var(--ColorMap-ContentDecorateColorFrg);
        }
        border-bottom: calc(var(--InputControl-FrameWidth) * 2px) solid var(--ColorMap-ContentDecorateColorFrg);
        margin-bottom: calc(var(--InputControl-FrameWidth) * -2px + 1);
      }
    }
  }

  .in_keyboard_img_center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
}

/* END */
