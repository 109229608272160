.sign {
  width: 100%;
  height: fit-content;
  background-color: var(--ColorMap-BaseColorFrg1);

  .sign_canvas {
    z-index: 2;
    touch-action: none;
    background-color: transparent;
    border: 2px dashed var(--ColorMap-ContentFrame2);
  }

  .sign_line {
    position: absolute;
    display: block;
    z-index: 1;
    width: 81%;
    margin-left: 8%;
    margin-right: 8%;
    padding-top: 1.5vh;
    border-top: 4px dotted var(--ColorMap-ContentFrame2);
    text-align: center;
    font-size: 150%;
    letter-spacing: 10;
    font-style: italic;
    color: var(--ColorMap-ContentFrame2);
  }

  .sign_clear {
    position: absolute;
    z-index: 3;
    top: 11vh;
    right: 5vh;
    height: max-content;
    width: max-content;
    background: none;
    border: none;
  }
}
