/* V případě zařízení s myší přidá specifické pseudotřídy.(:hover atp.) */

/* a links */
a:hover {
  color: #0054a6;
}

a:focus {
  color: #000;
}

button,
.button {
  position: relative;
}

button {
  color: #000;
}

button:hover::after,
button:focus-visible::after,
button:active::after,
.button:hover::after,
.button:focus-visible::after,
.button:active::after {
  content: "";
  position: absolute;
  inset: 0;
  display: block;
}

/* Prida preudotridu hover pro zarizeni, ktere ji podporuji */
@media (hover: hover) {
  button:hover::after,
  .button:hover::after {
    background-color: rgba(0, 0, 0, 0.17);
  }
}

/* Focus se objevi jen po stisknuti klavesy Tab */
button:focus-visible::after,
.button:focus-visible::after {
  background-color: rgba(0, 84, 166, 0.3);
}

button:active::after,
.button:active::after {
  background-color: rgba(0, 0, 0, 0.5);
}

button[disabled]:hover::after,
.button[disabled]:hover::after {
  background-color: transparent;
}

.mouse_only {
  display: inherit;
}

/* File upload */
#dropFileEnableText {
  display: block;
  text-align: center;
}
