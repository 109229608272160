@mixin font($weight, $style, $family, $size, $decoration) {
  font-weight: $weight;
  font-style: $style;
  font-family: $family;
  font-size: $size;
  text-decoration: $decoration;
}

@mixin label_font {
  @include font(
    var(--LabelControl-Font-Weight),
    var(--LabelControl-Font-Style),
    var(--LabelControl-Font-Name),
    calc(calc(var(--LabelControl-Font-Size) * var(--Zoom)) * 1pt),
    var(--LabelControl-Font-Decoration)
  );
}

@mixin expander_header_font {
  @include font(
    var(--ExpanderControl-HeaderFont-Weight),
    var(--ExpanderControl-HeaderFont-Style),
    var(--ExpanderControl-HeaderFont-Name),
    calc(calc(var(--ExpanderControl-HeaderFont-Size) * var(--Zoom)) * 1pt),
    var(--ExpanderControl-HeaderFont_Decoration)
  );
}

@mixin booktab_accessor_font {
  @include font(
    var(--BookTabControl-AccessorFont-Weight),
    var(--BookTabControl-AccessorFont-Style),
    var(--BookTabControl-AccessorFont-Name),
    calc(calc(var(--BookTabControl-AccessorFont-Size) * var(--Zoom)) * 1pt),
    var(--BookTabControl-AccessorFont_Decoration)
  );
}

@mixin grid_font {
  @include font(
    var(--GridControl-Font-Weight),
    var(--GridControl-Font-Style),
    var(--GridControl-Font-Name),
    calc(calc(var(--GridControl-Font-Size) * var(--Zoom)) * 1pt),
    var(--GridControl-Font_Decoration)
  );
}

@mixin grid_header_font {
  @include font(
    var(--GridControl-HeaderFont-Weight),
    var(--GridControl-HeaderFont-Style),
    var(--GridControl-HeaderFont-Name),
    calc(calc(var(--GridControl-HeaderFont-Size) * var(--Zoom)) * 1pt),
    var(--GridControl-HeaderFont_Decoration)
  );
}

@mixin input_label_font {
  @include font(
    var(--InputControl-LabelFont-Weight),
    var(--InputControl-LabelFont-Style),
    var(--InputControl-LabelFont-Name),
    calc(calc(var(--InputControl-LabelFont-Size) * var(--Zoom)) * 1pt),
    var(--InputControl-LabelFont)
  );
}

@mixin input_edit_font {
  @include font(
    var(--InputControl-EditFont-Weight),
    var(--InputControl-EditFont-Style),
    var(--InputControl-EditFont-Name),
    calc(calc(var(--InputControl-EditFont-Size) * var(--Zoom)) * 1pt),
    var(--InputControl-EditFont)
  );
}

@mixin list_view_font {
  @include font(
    var(--ListViewControl-Font-Weight),
    var(--ListViewControl-Font-Style),
    var(--ListViewControl-Font-Name),
    calc(calc(var(--ListViewControl-Font-Size) * var(--Zoom)) * 1pt),
    var(--ListViewControl-Font)
  );
}

@mixin tree_view_font {
  @include font(
    var(--TreeViewControl-Font-Weight),
    var(--TreeViewControl-Font-Style),
    var(--TreeViewControl-Font-Name),
    calc(calc(var(--TreeViewControl-Font-Size) * var(--Zoom)) * 1pt),
    var(--TreeViewControl-Font)
  );
}

@mixin dashboard_tile_font {
  @include font(
    var(--DashboardControl-TileFont-Weight),
    var(--DashboardControl-TileFont-Style),
    var(--DashboardControl-TileFont-Name),
    calc(calc(var(--DashboardControl-TileFont-Size) * var(--Zoom)) * 1pt),
    var(--DashboardControl-TileFont)
  );
}

@mixin dashboard_tile_small_font {
  @include font(
    var(--DashboardControl-TileSmallFont-Weight),
    var(--DashboardControl-TileSmallFont-Style),
    var(--DashboardControl-TileSmallFont-Name),
    calc(calc(var(--DashboardControl-TileSmallFont-Size) * var(--Zoom)) * 1pt),
    var(--DashboardControl-TileSmallFont)
  );
}

@mixin dashboard_filter_inner_font {
  @include font(
    var(--DashboardControl-FilterInnerFont-Weight),
    var(--DashboardControl-FilterInnerFont-Style),
    var(--DashboardControl-FilterInnerFont-Name),
    calc(calc(var(--DashboardControl-FilterInnerFont-Size) * var(--Zoom)) * 1pt),
    var(--DashboardControl-FilterInnerFont)
  );
}

@mixin dashboard_simple_table_font {
  @include font(
    var(--DashboardControl-SimpleTableFont-Weight),
    var(--DashboardControl-SimpleTableFont-Style),
    var(--DashboardControl-SimpleTableFont-Name),
    calc(calc(var(--DashboardControl-SimpleTableFont-Size) * var(--Zoom)) * 1pt),
    var(--DashboardControl-SimpleTableFont)
  );
}

@mixin title_font {
  @include font(
    var(--TitleControl-Font-Weight),
    var(--TitleControl-Font-Style),
    var(--TitleControl-Font-Name),
    calc(calc(var(--TitleControl-Font-Size) * var(--Zoom)) * 1pt),
    var(--TitleControl-Font)
  );
}
