.mst {
  background-color: white;
  width: 100%;
  overflow: hidden;
  .mst_item {
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    padding: 5px;
    position: relative;
    &.mst_current_page {
      color: var(--ColorMap-BaseColorBck1);
    }
    .tc_button_content {
      gap: 0;
      flex-direction: column;
    }

    &::before {
      position: absolute;
      content: " ";
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 90%;
      border-radius: 40%;
      transition: all 0.3s ease;
    }
    &:active::before {
      background-color: rgba(0, 0, 0, 0.15);
      width: 130%;
      height: 130%;
    }
  }
}
