.modal {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.3);
}

.modal_backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: clip;
}
