@use "./../VCX/base";

.fp {
  cursor: auto;
}

.fp_background {
  background-color: var(--ColorMap-ContentColorBck1);
}

.fp_background_preview {
  background-color: transparent;
}

.fp_background_edit {
  background-color: var(--ColorMap-ContentChangeColorBck);
}

.fp_main_content {
  padding: 3px;
}

.fp_preview {
  padding: 3px;
  @media only screen and (max-width: 980px) {
    padding: 0px;
  }
}
