@use "./../VCX/base";

.ac_wrapper {
  flex: 0 0 auto;

  .ac {
    @include base.label_font;
    background-color: transparent;
    color: inherit;
    border: none;
    position: relative;

    &.ac_checked {
      background-color: var(--ColorMap-ContentFrame1);
      border: calc(var(--Zoom) * 2px) solid var(--ColorMap-ContentFrame3);
    }
  }
}

.caption {
  margin: 0px 6px 0px 6px;

  &.caption_disabled {
    opacity: 0.5;
  }
}
