.modal_mobile_backdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.modal_mobile {
  position: relative;
  width: 95%;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.3);
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-fill-mode: both;

  &.maximized {
    width: 100%;
  }
}

.modal_mobile_local_modal {
  height: 97%;
  top: 1.5%;
  left: 2.5%;
  flex-direction: column;

  &.animation_open {
    animation-name: local_modal_open;
  }

  &.animation_close {
    animation-name: local_modal_close;
  }

  &.maximized {
    height: 100%;
    top: 0;
    left: 0;
  }
}

.modal_mobile_menu_modal {
  min-height: 90%;
  max-height: 93%;
  top: 1.5%;
  left: 2.5%;

  &.animation_open {
    animation-name: menu_modal_open;
  }

  &.animation_close {
    animation-name: menu_modal_close;
  }
}

.modal_mobile_notification {
  height: 96.5%;
  top: 3.5%;
  left: 0;

  &.animation_open {
    animation-name: notification_modal_open;
  }

  &.animation_close {
    animation-name: notification_modal_close;
  }
}

.modal_mobile_open_dialog {
  min-height: max-content;
  max-height: 96%;
  top: 1.5%;
  left: 2.5%;

  &.animation_open {
    animation-name: modal_open;
  }

  &.animation_close {
    animation-name: modal_close;
  }
}

.modal_mobile_modal {
  min-height: 96%;
  max-height: 96%;
  top: 1.5%;
  left: 2.5%;

  &.animation_open {
    animation-name: modal_open;
  }

  &.animation_close {
    animation-name: modal_close;
  }

  &.maximized {
    min-height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;

    &.animation_open {
      animation-name: modal_open_maximized;
    }

    &.animation_close {
      animation-name: modal_close_maximized;
    }
  }
}

.modal_mobile_dialog {
  height: auto;
  left: 2.5%;

  &.maximized {
    height: 100%;
    left: 0;
  }

  &.animation_open {
    animation-name: modal_open;
  }

  &.animation_close {
    animation-name: modal_close;
  }
}

// animations

@keyframes local_modal_open {
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes local_modal_close {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100vw);
  }
}

@keyframes menu_modal_open {
  0% {
    transform: translateY(calc(var(--vh, 1vh) * 100));
  }
  100% {
    transform: translateY(calc(5%));
  }
}

@keyframes menu_modal_close {
  0% {
    transform: translateY(calc(5%)); //cara pri otevreni
  }
  100% {
    transform: translateY(calc(var(--vh, 1vh) * 100));
  }
}

@keyframes notification_modal_open {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes notification_modal_close {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100vw);
  }
}

@keyframes modal_open {
  0% {
    transform: translateY(calc(var(--vh, 1vh) * 100));
  }
  100% {
    transform: translateY(calc(calc(var(--vh, 1vh) * 100) - 102%));
  }
}

@keyframes modal_close {
  0% {
    transform: translateY(calc(calc(var(--vh, 1vh) * 100) - 102%));
  }
  100% {
    transform: translateY(calc(var(--vh, 1vh) * 100));
  }
}

@keyframes modal_open_maximized {
  0% {
    transform: translateY(calc(var(--vh, 1vh) * 100));
  }
  100% {
    transform: translateY(calc(calc(var(--vh, 1vh) * 100) - 100%));
  }
}

@keyframes modal_close_maximized {
  0% {
    transform: translateY(calc(calc(var(--vh, 1vh) * 100) - 100%));
  }
  100% {
    transform: translateY(calc(var(--vh, 1vh) * 100));
  }
}
