.map {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
  .map_noresult {
    display: flex !important;
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 0.8em;
    z-index: 5;
  }
  .google_map {
    width: 100%;
    height: 100%;
    position: relative;
    iframe {
      border: 0;
      width: 100%;
      height: 100%;
    }
  }
  .osm_map {
    width: 100%;
    height: 100%;
    position: relative;
    .leaflet-container {
      width: 100%;
      height: 100%;
    }
    .leaflet-touch .leaflet-bar a {
      width: 25px;
      height: 25px;
      line-height: 25px;
    }

    div {
      display: block;
    }
  }
  .map_link {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999999;
    transition: all 0.3s ease;
    transform-origin: top right;
    div {
      display: flex;
    }
    &::before,
    &::after {
      transition: all 0.3s ease;
      position: absolute;
      content: " ";
      right: 0;
      top: 0;
    }
    &::before {
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 25px 25px 0;
      border-color: transparent var(--ColorMap-BaseColorBck1) transparent transparent;
    }
    &::after {
      width: 35px;
      height: 35px;
      transform: rotate(45deg) translateY(-25px);
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    }
    &:hover {
      transform: scale(1.25);
    }
    .img {
      margin: 1px;
      transform: rotate(90deg);
    }
    svg {
      fill: var(--ColorMap-BaseColorFrg1);
    }
  }
}
