@use "./../VCX/base";

.db_wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

.db {
  @include base.label_font;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  * {
    -webkit-user-select: text;
    user-select: text;
  }

  .db_header {
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;

    .db_title {
      @include base.expander_header_font;
      margin: 0 auto;
    }
  }

  .db_body {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    gap: 5px;

    .db_filter_title {
      @include base.expander_header_font;
      flex: 0 0 auto;
      align-items: center;
    }

    .db_filters {
      @include base.dashboard_filter_inner_font;
      align-items: center;
      gap: 5px;
      display: flex;
      flex: 1 1 auto;
      flex-wrap: wrap;
      overflow: auto;

      .db_filter_button {
        border: 1px solid #ccc;
        padding: 3px;
        font: inherit;
        background-color: #fff;

        &.db_filter_button_selected {
          border: 1px solid #67cef5;
          background-color: #67cef5;
          color: #fff;
        }
      }

      .db_select {
        display: flex;
        flex: 1 1 auto;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #ccc;
        background-color: #fff;
        padding: 2px 5px;

        &:hover {
          background-color: rgba(0, 0, 0, 0.17);
        }

        &:focus {
          background-color: rgba(0, 0, 0, 0.17);
        }

        &:active {
          background-color: rgba(0, 84, 166, 0.5);
        }

        .dg_select_text {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .db_wrap {
      display: flex;
      flex: 1 1 auto;
      gap: 5px;
    }

    .db_tile {
      display: flex;
      flex-direction: column;
      width: 330px;
      height: 140px;

      .db_tile_top {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        color: #fff;
        padding: 30px;
        white-space: normal;

        .dg_tile_title {
          @include base.dashboard_tile_font;
        }
      }

      .db_tile_bottom {
        display: flex;
        flex: 0 1 40px;
        justify-content: flex-end;
        align-items: center;
        color: #fff;
        gap: 5px;

        .dg_tile_kpi {
          @include base.dashboard_tile_small_font;
        }
      }
    }
  }
}

.db_part {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 5px 10px;

  .db_chart_header {
    display: flex;
    justify-content: space-between;
    flex: 0 0 auto;

    .db_chart_header_title {
      @include base.expander_header_font;
      margin: 0px auto;
    }

    .db_chart_header_controls {
      display: flex;
      gap: 5px;
    }
  }

  .db_chart {
    flex: 1 1 auto;
    overflow: hidden;

    .dg_grid {
      @include base.dashboard_simple_table_font;
      display: flex;
      overflow: auto;
      flex-direction: column;

      .db_table {
        color: inherit;

        .db_table_header {
          color: #fff;
          background-color: #0054a6;
          text-align: left;
          padding: calc(var(--Zoom) * 5px);
          font-weight: normal;
          width: calc(var(--Zoom) * 60px);
        }

        .db_table_cell {
          padding: calc(var(--Zoom) * 5px);
        }

        .db_table_cell:first-child {
          color: #fff;
          background-color: #0054a6;
          padding-right: calc(var(--Zoom) * 20px);
        }

        .db_table_cell:not(td:first-child) {
          text-align: right;
        }

        .db_table_row:nth-child(even) {
          background-color: #fcfcfc;
        }

        .db_table_row:nth-child(odd) {
          background-color: #f0f0f0;
        }
      }
    }
  }
}

.db_no_data {
  @include base.label_font;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
}

.db_backdrop {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 2;
  white-space: nowrap;

  &.db_menu_backdrop {
    align-items: flex-start;

    .db_menu {
      background: white;
      border: 1px solid #ccc;
      box-shadow: 3px 3px 7px 0px #00000033;
      flex-direction: column;
      position: relative;

      .db_menu_button {
        @include base.label_font;
        padding: 10px 50px;
        background-color: white;
        border: none;
        text-align: left;
      }
    }
  }

  .db_select_modal {
    position: relative;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 10px;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 3px 3px 7px 0px #00000033;

    .dg_modal_filter {
      display: flex;
      flex-direction: column;
      overflow: auto;

      .db_single_select_combo {
        flex: 0 0 auto;
        padding: 3px 5px;

        &.db_single_select_combo_selected {
          background-color: #c9eeff;
        }
      }

      .dg_tree_page {
        background: white;
        flex-direction: column;
        flex: 1 1 auto;
        overflow: auto;

        .dg_tree_member_toggle {
          width: 10px;
        }
      }

      .dg_member {
        display: flex;
        align-items: center;

        .dg_member_checkbox {
          padding: 5px;
        }
      }
    }

    .db_modal_controls {
      display: flex;
      align-items: center;
      gap: 5px;
      flex: 0 0 auto;

      .dg_modal_button {
        width: 100%;
        padding: 3px;
        font: inherit;
      }
    }
  }
}

// utils

.db_layout {
  min-height: 60px;
  gap: 5px;
}

.db_containers_horizontal {
  flex-direction: row;
}

.db_containers_vertical {
  flex-direction: column;
}

.db_containers_justify_start {
  justify-content: flex-start;
}

.db_containers_justify_center {
  justify-content: center;
}

.db_containers_justify_end {
  justify-content: flex-end;
}

.db_containers_justify_normal {
  justify-content: normal;
}

.db_containers_items_start {
  align-items: flex-start;
}

.db_containers_items_center {
  align-items: center;
}

.db_containers_items_end {
  align-items: flex-end;
}

.db_containers_items_normal {
  align-items: normal;
}

@media (max-width: 980px) {
  .db {
    .db_body {
      min-width: 0 !important;

      .db_wrap {
        flex-direction: column;
      }

      .db_tile {
        width: auto;
        height: auto;
      }
    }
  }

  .db_layout {
    &.db_layout_chart {
      min-height: 200px;
    }
  }

  .db_containers_horizontal {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto !important;
  }

  .db_containers_justify_start,
  .db_containers_justify_center,
  .db_containers_justify_end,
  .db_containers_justify_normal {
    justify-content: normal;
  }

  .db_containers_items_start,
  .db_containers_items_center,
  .db_containers_items_end,
  .db_containers_items_normal {
    align-items: normal;
  }
}
