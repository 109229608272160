@import "../../utils";

.cw {
  .cw_clock {
    min-height: 72px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    &.style_0 {
      .cw_clock_time {
        padding: 5px 5px;

        display: flex;
        justify-content: center;
        align-items: center;
        font-size: getZoom(30);
        font-weight: 500;
        .cw_separator {
          margin-top: -2px;
          font-weight: 600;
          padding: 0px 2px;
          font-size: getZoom(20);
        }
        .cw_clock_hours,
        .cw_clock_minutes,
        .cw_clock_seconds {
          width: calc(getZoom(25) * 1.4);
          height: calc(getZoom(25) * 1.4);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .cw_clock_date {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 2px;
        line-height: 1em;
      }
    }
    &.style_1 {
      .cw_clock_time {
        padding: 5px 5px;
        background: var(--ColorMap-AccentBaseColorBck);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: getZoom(25);
        font-weight: 500;
        .cw_separator {
          margin-top: -2px;
          font-weight: 600;
          padding: 0px 2px;
          color: var(--ColorMap-DataBrowseColorBck);
        }
        .cw_clock_hours,
        .cw_clock_minutes,
        .cw_clock_seconds {
          width: calc(getZoom(25) * 1.4);
          height: calc(getZoom(25) * 1.4);
          background: var(--ColorMap-DataBrowseColorBck);
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 0 7px 0px rgba(0, 0, 0, 0.1) inset;
        }
      }
      .cw_clock_date {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        padding: 2px;
      }
    }
  }
}
