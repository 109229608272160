.vk {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: var(--ColorMap-ContentFrame1);

  .vk_content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: flex-end;

    .vk_empty_row {
      height: calc(var(--GridControl-RowHeight) * 1px);
    }
  }
}
