@import "../utils";

.cw {
  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

  &.horizontal_left {
    justify-content: flex-start;
  }
  &.horizontal_center {
    justify-content: center;
  }
  &.horizontal_right {
    justify-content: flex-end;
  }

  &.vertical_top {
    align-items: flex-start;
  }
  &.vertical_center {
    align-items: center;
  }
  &.vertical_bottom {
    align-items: flex-end;
  }
}
