@import "../utils.scss";

.fsw {
  &[data-inline="0"] {
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
  &[data-inline="1"] {
    .fsw_content {
      .fsw_control {
        top: 5px;
        right: 5px;
      }
      .fsw_zoom {
        padding: 3px;
        font-size: 13px;
      }
    }
  }
  width: 100%;
  height: 100%;

  .fsw_header {
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    justify-content: space-between;
    .fsw_title {
      height: 100%;
      padding: 5px;
      justify-content: start;
      align-items: center;
      color: #ffffff;
      font-size: getZoom(16);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .fsw_close {
      svg {
        fill: #fff;
      }
      padding: 5px;
      cursor: pointer;
      transition: transform 0.3s ease;
      &:hover {
        transform: rotate(90deg);
      }
    }
  }
  .fsw_content {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    overflow: hidden;
    position: relative;
    .fsw_control {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      align-items: flex-start;
      & > * {
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
      .fsw_fullScreen,
      .fsw_zoom {
        padding: 5px;
        opacity: 0.8;
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
        background-color: rgba(0, 0, 0, 0.3);
        color: #fff;
        cursor: pointer;
        justify-content: center;
        svg {
          width: 17px;
          height: 17px;
          fill: #fff;
        }
        @media (max-width: 980px) {
          font-size: 20px;
          min-width: 40px;
          svg {
            width: 24px;
            height: 24px;
            fill: #fff;
          }
        }
      }
    }

    & .resizable {
      cursor: move;
      .img .non_svg {
        position: relative;
      }
    }

    .img {
      align-items: center;
      justify-content: center;
    }
    img {
      object-fit: unset;
      position: relative;
      width: unset;
      height: unset;
      -moz-user-select: none;
      -webkit-user-select: none;
      user-select: none;
      pointer-events: none;
    }
  }
}
