@import "../utils";

.tc_base {
  flex: 1 1 auto;
  flex-direction: column;

  &.tc_up {
    .tc_base_tabs {
      border-bottom: getZoom(2) solid var(--ColorMap-AccentBaseColorBck);
    }
  }
  &.tc_down {
    flex-direction: column-reverse;
    .tc_base_tabs {
      border-top: getZoom(2) solid var(--ColorMap-AccentBaseColorBck);
    }
  }

  .tc_base_tabs {
    flex: 0 0 auto;

    .img {
      margin: 0 auto;
    }

    .tc_base_button {
      padding: getZoom(5) getZoom(6);
      background-color: var(--ColorMap-BaseColorBck1);
      color: var(--ColorMap-BaseColorFrg1);
      border-right: 1px solid var(--ColorMap-BaseColorFrg1);
      flex: 0 0 auto;

      &.tc_current_page {
        background-color: var(--ColorMap-AccentBaseColorBck);
        text-shadow: 0px 0px 1px var(--ColorMap-BaseColorFrg1);
      }

      @media (max-width: 980px) {
        display: none;

        &.tc_current_page {
          display: flex;
          flex: 1 1 auto;
          border: none;
          justify-content: space-between;
        }
      }
    }
  }
}
