@import "../utils";

.tc_backdrop {
  display: none;

  @media (max-width: 980px) {
    display: flex;
    flex-direction: column;
    position: fixed;
    inset: 0;
    z-index: 10;
    align-items: center;
    background-color: rgb(255 255 255 / 0.8);
    overflow: auto;
    backdrop-filter: blur(10px);
    padding: getZoom(10);
    gap: getZoom(10);
  }
}

.tc_responsive_content {
  padding: getZoom(10);
  flex-direction: column;
  width: 100%;
  overflow: auto;
  flex: 1 1 auto;
  justify-content: center;

  .tc_responsive_buttons {
    flex-direction: column;
    gap: getZoom(10);

    .tc_responsive_button {
      display: none;

      @media (max-width: 980px) {
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        border: 1px solid transparent;
        background-color: transparent;
        padding: getZoom(7);
        transition: all 0.2s;
        cursor: pointer;

        &.tc_current_page {
          border: 1px solid var(--ColorMap-ContentFrame3ForChange);
          background-color: var(--ColorMap-ContentFrameMinForChange);

          &:hover {
            filter: brightness(90%);
          }
        }

        &:not(.tc_current_page):hover {
          border: 1px solid var(--ColorMap-ContentFrameMin);
          background-color: white;
        }

        &:hover {
          box-shadow: 0px 5px 20px #0000001a;
        }

        p {
          font-size: getZoom(16);
        }
      }
    }
  }
}

.tc_close {
  display: none;

  @media (max-width: 980px) {
    display: flex;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s;
    align-self: flex-end;
    flex: 0 0 auto;

    &:hover {
      transform: rotate(90deg);
      border: 1px solid var(--ColorMap-ContentFrameMin);
      background-color: white;
    }
  }
}

.tc_responsive_button_up {
  opacity: 0;
  animation: animation_translate_up 0.5s forwards;
}

.tc_responsive_button_down {
  animation: animation_translate_down 0.5s forwards;
}

.tc_responsive_backdrop_open {
  animation: animation_opacity_open 0.5s;
}

.tc_responsive_backdrop_close {
  animation: animation_opacity_close 0.75s forwards;
}

@keyframes animation_translate_up {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animation_translate_down {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100px);
  }
}

@keyframes animation_opacity_open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animation_opacity_close {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
