@use "./../VCX/base";

.tw_inner {
  @include base.tree_view_font;
}

.tw_table {
  color: inherit;

  & .ant-table-body {
    color: var(--ColorMap-DataBrowseColorFrg);
    background-color: var(--ColorMap-DataBrowseColorBck);
  }

  &.tw_table_edit {
    & .ant-table-body {
      color: var(--ColorMap-DataChangeColorFrg);
      background-color: var(--ColorMap-DataChangeColorBck);
    }
  }

  & table {
    height: min-content;
  }
}

.ant-table {
  color: unset;
  font-size: unset;
  line-height: unset;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: inherit;
  border: 0;
}

.ant-table-wrapper,
.ant-spin-nested-loading,
.ant-spin-container,
.ant-table,
.ant-table-container,
.ant-table-body {
  flex: 1 1 auto;
}

.ant-table-body {
  overflow: auto !important;
}

.ant-table-container {
  flex-direction: column;
  border: 1px solid var(--ColorMap-ContentFrame1);
  border-radius: 0px;
}

tr > .ant-table-cell {
  padding: 0px 2px !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: transparent;
}

.ant-table-header {
  border-bottom: 1px solid var(--ColorMap-ContentFrame1);
  background-color: var(--ColorMap-ContentColorBck1);
  flex: 0 0 auto;
}

.ant-table-small .ant-table-thead > tr > th {
  color: var(--ColorMap-ContentDecorateColorFrg);
  background-color: var(--ColorMap-ContentColorBck1);
}

.antd-denied-text {
  background-color: var(--ColorMap-ErrorColorBck);
  color: var(--ColorMap-ErrorColorFrg);
}

.antd-denied-text div {
  width: min-content;
  margin: auto;
}

.ant-table-tbody > tr {
  border-right: 1px solid var(--ColorMap-ContentFrame1);
}

.border-top {
  border-top: 2px solid var(--ColorMap-BaseColorBck1);
  border-bottom: 2px solid transparent;
  border-radius: 4px;
  border-left: 1px solid transparent;
}

.border-bottom {
  border-bottom: 2px solid var(--ColorMap-BaseColorBck1);
  border-top: 2px solid transparent;
  border-radius: 4px;
  border-left: 1px solid transparent;
}

.border-transparent {
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}

.border-select-node {
  border: 1px dashed var(--ColorMap-BaseColorBck1) !important;
  padding: 1px 0;
  border-radius: 4px;
  background: var(--ColorMap-GridRulerColorBck);
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: var(--ColorMap-ContentNormalColorFrg);
  background-color: var(--ColorMap-ContentChangeColorBck);
}

.ant-table-row-expand-icon::after {
  top: 3px !important;
  bottom: 3px !important;
  left: 7px !important;
  opacity: 1 !important;
  background-color: currentColor !important;
}

.ant-table-tbody > tr > td {
  border-bottom: 0px solid;
}

.ant-table.ant-table-small .ant-table-thead > tr > th {
  padding: 0 6px;
}

.ant-table.ant-table-small {
  font-size: inherit;
}

tr {
  height: calc(var(--GridControl-RowHeight) * 1px);
}

.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  display: none;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: var(--ColorMap-ContentFrame1);
  height: calc(100% + 2px);
}

.ant-table-selection-col {
  display: none;
}

.ant-checkbox-input {
  margin-top: 4.5px;
}

.ant_checkbox_wrap {
  display: flex;
  width: 100%;
  & > * {
    display: flex;
  }
  & > *:first-child {
    flex: 0 0 auto;
    padding-right: 0;
  }
}
